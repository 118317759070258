import Head from 'next/head';
import { type ReactElement } from 'react';

import { env } from '@replay/env-generator';
import getServerName from '../getServerName';
import { type Locale } from '@replay/i18n/types/locale';
import { type Meta } from '@replay/types/Meta';
import { type Language, getAlternateTags } from './AlternateTags';
import { defaultImageStorage, defaultShareImage } from './constants';
import { getIcons } from './Icons';
import { getSocialTags } from './SocialTags';
import { getBaseTags } from './BaseTags';
import path from 'ramda/src/path';
import { concatWithReplayBaseUrl } from './concatWithReplayBaseUrl';

type MetaProps = {
    title: string;
    description: string;
    language: Locale;
    url: string;
    alternativeLanguages: Language[] | null;
    meta: Meta;
};

export type MetaWithPageProps = MetaProps & { page: string };

const ensurePageMetas = (meta: Meta): Meta => {
    const ensuredMetas = { ...meta };
    const twitterImage = path<string>(['twitter', 'image', 'url'], meta);
    const ogImage = path<string>(['og', 'image', 'url'], meta);
    if (!ogImage || ogImage?.startsWith(defaultImageStorage)) {
        ensuredMetas.og.image.url = `${env.NEXT_PUBLIC_CDN_URL}${defaultShareImage}`;
    }
    if (!twitterImage || twitterImage?.startsWith(defaultImageStorage)) {
        ensuredMetas.twitter.image.url = `${env.NEXT_PUBLIC_CDN_URL}${defaultShareImage}`;
    }
    return { ...ensuredMetas };
};

export const Metas = ({ title, description, url, language, alternativeLanguages, meta }: MetaProps): ReactElement => {
    const serverName = getServerName();
    const titlePrefix = serverName === 'production' ? '' : `(${serverName}) `;

    const pageMetas = ensurePageMetas(meta);

    const ensuredUrl = url.startsWith('http') ? url : concatWithReplayBaseUrl(url);

    const baseTags = getBaseTags({ language });
    const socialTags = getSocialTags({
        ensuredUrl,
        language,
        title,
        description,
        meta: pageMetas,
    });
    const icons = getIcons();
    const alternateTags = getAlternateTags({ languages: alternativeLanguages });

    return (
        <Head>
            <title>{`${titlePrefix}${title}`}</title>
            {title && <meta key="og:title" property="og:title" content={title} />}
            {description && <meta key="description" name="description" content={pageMetas.description} />}
            {description && <meta key="og:description" property="og:description" content={pageMetas.description} />}
            {url && <link key="canonical" rel="canonical" href={ensuredUrl} />}
            {url && <meta key="og:url" property="og:url" content={ensuredUrl} />}

            {baseTags}
            {socialTags}
            {icons}
            {alternateTags}
        </Head>
    );
};
