import { env } from '@replay/env-generator';

const SERVER_NAMES: string[] = ['production', 'preview', 'preproduction', 'local', 'development'];

const getServerName = (): string => {
    const serverName = env.NEXT_PUBLIC_SERVER_NAME;
    if (!serverName || !SERVER_NAMES.includes(serverName)) {
        return 'production';
    }
    return serverName;
};
export default getServerName;
