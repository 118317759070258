import { env } from '@replay/env-generator';

import getServerName from '../getServerName';
import { appleTouchIconSizes, faviconSizes } from './constants';

type ServerFavicon = {
    server: string;
    faviconPrefix: string;
};

const ENV_FAVICONS: ServerFavicon[] = [
    { server: 'production', faviconPrefix: '' },
    { server: 'preview', faviconPrefix: 'green-' },
    { server: 'preproduction', faviconPrefix: 'blue-' },
    { server: 'development', faviconPrefix: 'green-' },
    { server: 'local', faviconPrefix: 'yellow-' },
];

const getFavicons = () => {
    const serverName = getServerName();
    if (serverName !== 'production') {
        const faviconPrefix = ENV_FAVICONS.find((icon) => icon.server === getServerName())?.faviconPrefix;
        return [
            <link key="favicon" rel="icon" href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPrefix}favicon.ico`} />,
        ];
    }
    const favicons = faviconSizes.map((size) => (
        <link
            key={`icon${size}`}
            rel="icon"
            type="image/png"
            sizes={size}
            href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/favicon-${size}.png`}
        />
    ));

    return [
        ...favicons,
        <link key="mask-icon" rel="mask-icon" href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/safari-pinned-tab.svg`} />,
        <link key="shortcut icon" rel="shortcut icon" href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/favicon.ico`} />,
    ];
};

const getAppleTouchIcons = () =>
    appleTouchIconSizes.map((size) => (
        <link
            key={`apple-touch-icon-${size}`}
            rel="apple-touch-icon"
            sizes={size}
            href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/apple-touch-icon-${size}.png`}
        />
    ));

export const getIcons = () => {
    return [
        <link
            key="apple-touch-icon"
            rel="apple-touch-icon"
            href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/apple-touch-icon-114x114.png`}
        />,
        <link
            key="apple-touch-icon-precomposed"
            rel="apple-touch-icon-precomposed"
            href={`${env.NEXT_PUBLIC_CDN_URL}/favicons/apple-touch-icon-114x114.png`}
        />,
        ...getAppleTouchIcons(),
        ...getFavicons(),
    ];
};
